.btn.btn-bv-primary {
  color: #fff;
  background-color: #006EB6;
  border-color: #006EB6;
  font-weight: 700;
  font-size: 16px;
}

.bv-disclaimer {
  font-weight: 700;
}

.social-distancing-header {
  color: #075697;
}

.social-distancing-message {
  font-weight: 700;
}

.learn-more-text {
  color: black;
}

h3 {
  font-size: 28px;
  font-weight: 700;
  font-family: Raleway, sans-serif;
}

h4 {
  font-size: 24px;
  font-weight: 700;
  font-family: Raleway, sans-serif;
}

.header-link {
  text-decoration: none;
  color: black;
  font-weight: 700;
}

.header-link:hover {
  color: #256ee5;
}

.bv-headquarters-img {
  min-height: 250px;
}

/* bv.com styles */
footer#global-footer {
  color: #fefefe;
  background: #111;
  font-size: .9rem;
}

.pb-3, .py-3 {
  padding-bottom: 1rem!important;
}

.pt-3, .py-3 {
  padding-top: 1rem!important;
}

.block--footersocialmedia {
  text-align: left;
}

@media (min-width: 768px) {
  .block--footersocialmedia {
    text-align: right;
  }
}

.block--footersocialmedia a img, .block--footersocialmedia a svg {
  width: 100%;
  height: 100%;
}

.block--footersocialmedia a {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: .25rem;
}

footer#global-footer a {
  color: #fefefe;
}

footer#global-footer {
  color: #fefefe;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

footer#global-footer .nav li:first-child a {
  padding-left: 0;
}

footer#global-footer a:hover {
  color: #256ee5;
}

.block--footersocialmedia a:hover svg, .block--footersocialmedia a:hover svg circle, .block--footersocialmedia a:hover svg path, .block--footersocialmedia a:hover svg polygon, .block--footersocialmedia a:hover svg rect {
  fill: #256ee5!important;
}
/* end bv.com styles */
